"use client";

import React, { useMemo } from "react";
import CalendarEventBlock from "./CalendarEventBlock";
import { Event, Workspace } from "@/db/schema";

function row(event: Event, workspace: Workspace) {
  const startBlock =
    1 +
    (new Date(event.startDate).getHours() * 2 +
      new Date(event.startDate).getMinutes() / 30) -
    workspace.openHour * 2;
  const endBlock =
    1 +
    (new Date(event.endDate).getHours() * 2 +
      new Date(event.endDate).getMinutes() / 30) -
    workspace.openHour * 2;

  const span = endBlock - startBlock;

  return `${Math.round(startBlock)} / span ${Math.round(span)}`;
}

export default function CalendarEvents({
  events,
  workspace,
}: {
  events: Event[];
  workspace: Workspace;
}) {
  return (
    <>
      {events.map((event) => (
        <div
          key={event.id}
          className="col-span-1 col-start-2 md:col-start-1 p-1"
          style={{
            gridRow: row(event, workspace),
          }}
        >
          <CalendarEventBlock event={event} workspace={workspace} />
        </div>
      ))}
    </>
  );
}
