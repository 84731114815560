"use client";

import {
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@radix-ui/react-dialog";
import clsx from "clsx";
import Image from "next/image";
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Room } from "@/db/schema";

function roomColor(str: string | undefined | null) {
  if (!str) return "#000000";
  let hash = 0;
  let color = "#";

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    value = Math.floor((value + 255) / 2);
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}

export function CalendarTitle({ room }: { room: Room }) {
  const [imageError, setImageError] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div
          className="sticky top-0 bg-white border-b p-2 min-w-[120px] sm:w-auto w-full cursor-pointer hover:opacity-70 transition-opacity"
          style={{
            height: "var(--title-height)",
          }}
        >
          <div className="relative w-full h-full">
            <div className="h-full w-full object-cover rounded-md overflow-hidden flex items-center">
              {room.imageId && !imageError ? (
                <Image
                  priority
                  onError={() => {
                    setImageError(true);
                  }}
                  onLoad={() => {
                    setImageLoaded(true);
                  }}
                  src={`https://d36zv2buqa7jh.cloudfront.net/${room.imageId}`}
                  alt="Room background"
                  width={600}
                  height={600}
                  className={clsx(
                    "min-w-full min-h-full transition-opacity",
                    imageLoaded ? "opacity-100" : "opacity-0"
                  )}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: roomColor(room.name),
                  }}
                  className="top-0 left-0 h-full w-full object-cover rounded-md overflow-hidden"
                ></div>
              )}
            </div>
            <div className="mt-auto absolute bottom-0 left-0 w-full p-2">
              <div className="bg-white rounded p-2 text-sm">
                <h2 className="truncate font-semibold">{room.name}</h2>
                <p className="text-gray-500 text-xs truncate">
                  {room.capacity ? <span>{room.capacity} people. </span> : null}
                  {room.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="text-start">
          <DialogTitle className="font-bold">{room.name}</DialogTitle>
          {room.capacity ? (
            <DialogDescription className="text-sm">
              {room.capacity} people
            </DialogDescription>
          ) : null}
        </DialogHeader>
        <p className="text-gray-500 text-sm">{room.description}</p>
        <div className="rounded object-cover overflow-hidden">
          {room.imageId && !imageError ? (
            <Image
              priority
              onError={() => {
                setImageError(true);
              }}
              src={`https://d36zv2buqa7jh.cloudfront.net/${room.imageId}`}
              width={400}
              height={400}
              alt="Room background"
              className="w-full h-full"
            />
          ) : (
            <div className="top-0 left-0 h-full w-full object-cover rounded-md overflow-hidden"></div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
