"use client";

import { Room, Workspace } from "@/db/schema";
import { useAuth } from "@clerk/nextjs";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { CreateEventSheet } from "./CreateEventSheet";

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.getTime());
}

export function CalendarBlock({
  index,
  room,
  workspace,
  from,
}: {
  index: number;
  room: Room;
  workspace: Workspace;
  from: Date;
}) {
  const [open, setOpen] = useState(false);
  const { orgRole } = useAuth();

  const [_startTime, setStartTime] = useState(new Date());
  const [_endTime, setEndTime] = useState(new Date());

  const startTime = useMemo(() => {
    return isValidDate(_startTime)
      ? _startTime
      : new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0);
  }, [_startTime, from]);

  const endTime = useMemo(() => {
    return isValidDate(_endTime)
      ? _endTime
      : new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0);
  }, [_endTime, from]);

  useEffect(() => {
    const hour = Math.floor(index / 2) + workspace.openHour;
    const minute = (index % 2) * 30;
    const _startTime = new Date(from);
    _startTime.setHours(hour, minute);

    const _endTime = new Date(_startTime);
    _endTime.setMinutes(_endTime.getMinutes() + 30);

    setStartTime(_startTime);
    setEndTime(_endTime);
  }, [from, index, workspace.openHour]);

  const isAdmin = useMemo(() => {
    return orgRole === "admin";
  }, [orgRole]);

  const bookable = useMemo(() => {
    return isAdmin || room.bookableByPublic || !workspace.requireAuth;
  }, [isAdmin, room.bookableByPublic, workspace.requireAuth]);

  return (
    <>
      <div
        onClick={() => {
          if (bookable) setOpen(true);
        }}
        style={{ gridRow: index + 1, height: "var(--block-height)" }}
        className={clsx(
          "col-span-1 md:col-start-1 col-start-2 ",
          bookable && "hover:bg-gray-100 cursor-pointer"
        )}
      ></div>
      <CreateEventSheet
        open={open}
        setOpen={setOpen}
        room={room}
        startTime={startTime}
        endTime={endTime}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
      />
    </>
  );
}
