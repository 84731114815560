"use client";

import { useSearchParams } from "next/navigation";
import React, { createContext, useContext, useEffect, useState } from "react";

interface PageLoadingContextType {
  isPageLoading: boolean;
  setPageLoading: (isPageLoading: boolean) => void;
}

const PageLoadingContext = createContext<PageLoadingContextType | undefined>(
  undefined
);

export function usePageLoading() {
  const pageLoadingContext = useContext(PageLoadingContext);
  if (!pageLoadingContext) {
    throw new Error("usePageLoading must be used within a PageLoadingProvider");
  }
  return pageLoadingContext;
}

export default function PageLoadingProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isPageLoading, setPageLoading] = useState(false);
  const searchParams = useSearchParams();

  useEffect(() => {
    setPageLoading(false);
  }, [searchParams]);

  return (
    <PageLoadingContext.Provider value={{ isPageLoading, setPageLoading }}>
      {children}
    </PageLoadingContext.Provider>
  );
}
