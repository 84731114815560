"use client";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { usePageLoading } from "@/context/LoadingProvider";
import { Workspace } from "@/db/schema";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { ArrowLeft, ArrowRight, CalendarIcon, Loader2 } from "lucide-react";
import { useRouter } from "next/navigation";
import { useCallback, useMemo, useState, useTransition } from "react";

const createSearchString = (from: Date, to: Date) => {
  return `?from=${from.toISOString()}&to=${to.toISOString()}`;
};

export default function CalendarTools({
  workspace,
  from,
  to,
  datesWithEvents,
}: {
  workspace: Workspace;
  from: Date;
  to: Date;
  datesWithEvents: Date[];
}) {
  const router = useRouter();
  const [_, startTransition] = useTransition();
  const [selectedDate, setSelectedDate] = useState(from);
  const { isPageLoading, setPageLoading } = usePageLoading();

  const today = useMemo(() => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  }, []);

  const selectDate = useCallback(
    (d1: Date) => {
      setPageLoading(true);
      const d2 = new Date(d1);
      d2.setHours(23, 59, 59, 999);
      setSelectedDate(d1);
      startTransition(() => {
        router.push(createSearchString(d1, d2));
      });
    },
    [router, setPageLoading]
  );

  const setToday = useCallback(() => {
    selectDate(today);
  }, [selectDate, today]);

  const isToday = (d: Date) => {
    const t = new Date();
    return (
      d.getDate() === t.getDate() &&
      d.getMonth() === t.getMonth() &&
      d.getFullYear() === t.getFullYear()
    );
  };

  const css = `
    .my-highlighted::after { 
      content: '';
      display: block;
      margin-top: 22px;
      height: 5px;
      width: 5px;
      background-color: black;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  `;

  return (
    <div className="h-[50px] justify-end flex flex-row gap-2 px-2 md:px-0 py-2 items-center">
      {workspace?.imageUrl && (
        <img
          src={workspace.imageUrl}
          alt={"logo"}
          className="mr-auto h-[25px] md:h-[35px] w-auto"
        />
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "w-[140px] pl-3 text-left font-normal",
              !selectedDate && "text-muted-foreground"
            )}
          >
            {isPageLoading ? (
              <div>
                <Loader2 className="animate-spin h-4 w-4" />
              </div>
            ) : selectedDate ? (
              format(selectedDate, "PP")
            ) : (
              <span>Pick a date</span>
            )}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <>
            <style>{css}</style>
            <Calendar
              weekStartsOn={1}
              mode="single"
              selected={selectedDate}
              onSelect={(date) => {
                if (!date) return;
                selectDate(date);
              }}
              // disabled={(date) => date < today}
              initialFocus
              modifiersClassNames={{
                highlighted: "my-highlighted", // Apply custom class to highlighted days
              }}
              modifiers={{
                highlighted: datesWithEvents,
              }}
            />
          </>
        </PopoverContent>
      </Popover>
      <Button
        className="px-2"
        variant={"outline"}
        onClick={() => {
          const d = new Date(selectedDate);
          d.setDate(d.getDate() - 1);
          selectDate(d);
        }}
      >
        <ArrowLeft className="w-4 h-4" />
      </Button>
      <Button
        className="px-2"
        variant={"outline"}
        onClick={() => {
          const d = new Date(selectedDate);
          d.setDate(d.getDate() + 1);
          selectDate(d);
        }}
      >
        <ArrowRight className="w-4 h-4" />
      </Button>
      <Button
        className="px-3 md:px-4"
        variant={isToday(selectedDate) ? "default" : "ghost"}
        onClick={setToday}
      >
        <span>Today</span>
      </Button>
    </div>
  );
}
