import { useEffect, useState } from "react";

export const useSheetSide = () => {
  const [side, setSide] = useState<"bottom" | "right">("bottom");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setSide("right");
      } else {
        setSide("bottom");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return side;
};
