"use client";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Room } from "@/db/schema";
import { useSheetSide } from "@/hooks/useSheetSide";
import { createEvent } from "@/lib/createEvent";
import { useClerk } from "@clerk/nextjs";
import { Loader2 } from "lucide-react";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { useFormState, useFormStatus } from "react-dom";
import { toast } from "sonner";

const initialState = {
  message: "",
  error: "",
  count: 0,
};

interface CreateEventSheetProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  room: Room;
  startTime: Date;
  endTime: Date;
  setStartTime: (time: Date) => void;
  setEndTime: (time: Date) => void;
}

export function CreateEventSheet({
  open,
  setOpen,
  room,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
}: CreateEventSheetProps) {
  const [state, formAction] = useFormState(createEvent, initialState);
  const [prevCount, setPrevCount] = useState(0);
  const side = useSheetSide();
  const router = useRouter();
  const { openSignIn, openSignUp, user, loaded } = useClerk();

  useEffect(() => {
    if (state.message && state.count > prevCount) {
      toast.success(state.message);
      setOpen(false);
      router.refresh();
      setPrevCount(state.count);
    } else if (state.error && state.count > prevCount) {
      toast.error(state.error);
      setPrevCount(state.count);
    }
  }, [state.message, state.count, state.error, prevCount, router, setOpen]);

  const onChangeStartTime = (time: string) => {
    const [hours, minutes] = time.split(":");
    const s = new Date(startTime);
    s.setHours(parseInt(hours), parseInt(minutes));
    setStartTime(s);
  };

  const onChangeEndTime = (time: string) => {
    const [hours, minutes] = time.split(":");
    const e = new Date(endTime);
    e.setHours(parseInt(hours), parseInt(minutes));
    setEndTime(e);
  };

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent
        side={side}
        className="text-start justify-start items-start"
      >
        <SheetHeader className="text-start space-y-0">
          <SheetTitle>
            Book <span className="text-blue-500">{room.name}</span>
          </SheetTitle>
          <SheetDescription>
            <span className="capitalize">
              {startTime.toLocaleDateString("en-GB", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </span>
          </SheetDescription>
        </SheetHeader>
        <form action={formAction} className="flex flex-col gap-2 md:gap-4 my-4">
          <div className="flex flex-col gap-2">
            <Label htmlFor="name">Title</Label>
            <Input
              id="title"
              name="title"
              placeholder="ex. Morning meeting"
              className="col-span-3"
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="authorName">Booked by</Label>
            <Input
              id="authorName"
              name="authorName"
              placeholder="ex. John Doe"
              className="col-span-3"
              defaultValue={
                user?.fullName ||
                user?.username ||
                user?.primaryEmailAddress?.emailAddress
              }
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="authorCompany">Company</Label>
            <Input
              id="authorCompany"
              name="authorCompany"
              placeholder="ex. E-corp"
              className="col-span-3"
              defaultValue={
                user?.primaryEmailAddress?.emailAddress
                  ?.split("@")?.[1]
                  ?.split(".")?.[0] ?? ""
              }
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="startTime">Start time</Label>
            <Input
              type="time"
              placeholder=""
              value={startTime.toTimeString().slice(0, 5)}
              className="col-span-3"
              onChange={(e) => {
                onChangeStartTime(e.target.value);
              }}
            />
            <input
              type="hidden"
              name="startTime"
              value={startTime.toISOString()}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="endTime">End time</Label>
            <Input
              type="time"
              placeholder=""
              value={endTime.toTimeString().slice(0, 5)}
              className="col-span-3"
              onChange={(e) => {
                const value = e.target.value;
                if (
                  !value ||
                  !/^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/.test(value)
                ) {
                  onChangeEndTime("");
                } else {
                  onChangeEndTime(value);
                }
              }}
            />
            <input type="hidden" name="endTime" value={endTime.toISOString()} />
          </div>
          <div className="flex flex-row gap-1">
            <Button
              variant={"ghost"}
              type="button"
              onClick={() => {
                setEndTime(new Date(startTime.getTime() + 30 * 60 * 1000));
              }}
            >
              30min
            </Button>
            <Button
              variant={"ghost"}
              type="button"
              onClick={() => {
                setEndTime(new Date(startTime.getTime() + 60 * 60 * 1000));
              }}
            >
              1h
            </Button>
            <Button
              variant={"ghost"}
              type="button"
              onClick={() => {
                setEndTime(new Date(startTime.getTime() + 90 * 60 * 1000));
              }}
            >
              1h 30min
            </Button>
            <Button
              variant={"ghost"}
              type="button"
              onClick={() => {
                setEndTime(new Date(startTime.getTime() + 120 * 60 * 1000));
              }}
            >
              2h
            </Button>
          </div>
          <input type="hidden" name="roomId" value={room.id} />
          <input type="hidden" name="date" value={startTime.toISOString()} />

          <SubmitButton />
        </form>
      </SheetContent>
    </Sheet>
  );
}

const SubmitButton = () => {
  const { pending } = useFormStatus();

  return (
    <Button type="submit" disabled={pending}>
      {pending && <Loader2 className="animate-spin mr-2" />}
      Create booking
    </Button>
  );
};
