"use client";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Event, Workspace } from "@/db/schema";
import { deleteEvent } from "@/lib/deleteEvent";
import { useAuth, useUser } from "@clerk/nextjs";
import { Loader2 } from "lucide-react";
import { useRouter } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import { useFormState, useFormStatus } from "react-dom";
import { toast } from "sonner";
import { Button } from "../ui/button";

export default function CalendarEventBlock({
  workspace,
  event,
}: {
  workspace: Workspace;
  event: Event;
}) {
  const [windowWidth, setWindowWidth] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <div
          onClick={() => setOpen(true)}
          className="flex w-full flex-col rounded-md bg-blue-50 py-1 px-2 text-blue-500 text-xs hover:bg-blue-100 overflow-hidden h-full cursor-pointer text-start"
        >
          <p>
            {event.startDate.toLocaleTimeString("sv-SE", {
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
            -{" "}
            {event.endDate.toLocaleTimeString("sv-SE", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
          <p>{event.title}</p>
          <p>{event.authorCompany}</p>
        </div>
      </SheetTrigger>
      <SheetContent side={windowWidth > 768 ? "right" : "bottom"}>
        <SheetHeader className="text-start">
          <SheetTitle className="leading-5">
            {event.title}
            <br />
          </SheetTitle>
          <SheetDescription className="flex flex-col">
            <span>
              {event.startDate.toLocaleTimeString("sv-SE", {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -{" "}
              {event.endDate.toLocaleTimeString("sv-SE", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
            <span>
              Bokat av <span className="text-blue-500">{event.authorName}</span>{" "}
              på <span className="text-blue-500">{event.authorCompany}</span>
            </span>
          </SheetDescription>
        </SheetHeader>
        <SheetFooter>
          <DeleteEventButton
            requireAuth={workspace.requireAuth}
            event={event}
            onDelete={() => {
              setOpen;
            }}
          />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}

const initialState = {
  message: "",
  error: "",
  count: 0,
};
const DeleteEventButton = ({
  requireAuth,
  event,
  onDelete,
}: {
  requireAuth: boolean;
  event: Event;
  onDelete: () => void;
}) => {
  const { orgRole } = useAuth();
  const { user } = useUser();
  const router = useRouter();

  const [state, formAction] = useFormState(deleteEvent, initialState);
  const [prevCount, setPrevCount] = useState(0);

  useEffect(() => {
    if (state.message && state.count > prevCount) {
      toast.success(state.message);
      onDelete();
      router.refresh();
    } else if (state.error && state.count > prevCount) {
      toast.error(state.error);
    }
  }, [state.message, state.count, state.error, prevCount, router, onDelete]);

  const admin = useMemo(() => {
    return orgRole === "admin";
  }, [orgRole]);

  const creator = useMemo(() => {
    if (!requireAuth) return true;
    return user?.id === event.clerkId;
  }, [requireAuth, user?.id, event.clerkId]);

  if (admin || creator)
    return (
      <form action={formAction} className="mt-4 w-full">
        <input type="hidden" name="id" value={event.id} />
        <DeleteButton />
      </form>
    );

  return null;
};

const DeleteButton = () => {
  const { pending } = useFormStatus();

  return (
    <Button
      type="submit"
      variant={"destructive"}
      disabled={pending}
      className="w-full"
    >
      {pending && <Loader2 className="animate-spin mr-2" />}
      Delete booking
    </Button>
  );
};
